












































import { Component, Prop } from 'vue-property-decorator';
import { AuthorizationMixin } from '@/views/mixin';
import { TermView } from '@/modules/term/types';

@Component({})
export default class PluginList extends AuthorizationMixin {
  @Prop({ type: Array, default: () => [] }) items!: TermView[];
  @Prop({ type: Function }) itemClasses!: (term: TermView) => string;
  @Prop({ type: Boolean, default: false }) loading!: boolean;
  @Prop({ type: Number, default: -1 }) itemsPerPage!: number;

  lines(): string {
    let height = 3;

    if (this.isAdmin()) {
      height = height + 1;
    }

    return `line-${height}`;
  }
}
