import { render, staticRenderFns } from "./PluginList.vue?vue&type=template&id=fef3ac04&"
import script from "./PluginList.vue?vue&type=script&lang=ts&"
export * from "./PluginList.vue?vue&type=script&lang=ts&"
import style0 from "./PluginList.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDataIterator } from 'vuetify/lib/components/VDataIterator';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VDataIterator,VDivider,VList,VListItem,VListItemContent,VListItemSubtitle,VListItemTitle,VTooltip})
